import logo from './logo.svg';
import { ScrollingProvider } from 'react-scroll-section';
import { Header, Body } from './layout';
import './App.css';

function App() {
  return (
    <div className="App">
      <ScrollingProvider>
        <Header />
        <Body />
      </ScrollingProvider>
    </div>
  );
}

export default App;
