import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Button } from "@material-ui/core";
import { Grid, Box, Container } from "@material-ui/core"
import { useScrollSections } from "react-scroll-section";
import MenuIcon from "@material-ui/icons/Menu"
import { makeStyles } from "@material-ui/core";
import { useState, useEffect, useRef } from "react";
import React from "react";
import { SandOneMenu } from "../components"

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexGrow: 1,
        alignItems: 'center',
    },
    headerStyleClass: {
       backgroundColor: "#76a6f5",
    },
    logo: {
        fontFamily: "Work Sans, sans-serif",
        fontWeight: 600,
        color: "#FFFEFE",
        textAlign: "right",
    },
    menuicon: {
        marginLeft: -12,
        marginRight: 20,
        anchor: "left",
    },
    desktopMenu: {
    },
    toolbar: {
        flaxGrow: 1,
        width: "100%",
    },
    menuItem: {
    },
    grow: {
        flexGrow: 1,
    }
 }));

export default function Header() {
    const [state, setAllStateValues] = useState({
        mobileView: false,
        drawerOpen: false,
        anchorEl: null,
    });
    const menuButtonRef = useRef(null);


    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
            ? setAllStateValues((prevState) => ({ ...prevState, mobileView: true }))
            : setAllStateValues((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        }
    }, []);

    const classes = useStyles();
    const scrollSections = useScrollSections();
    const handleDrawerOpen = (event) => {
        setAllStateValues((prevState) => ({ ...prevState, anchorEl: event.currentTarget }));
    }
    const handleDrawerClose = () => {
        setAllStateValues((prevState) => ({ ...prevState, anchorEl: null }));
    }

    const sandoneappsLogo = (
            <Typography variant="h6" component="h1" className={classes.logo} anchor="left">SandOneApps</Typography>
    );

    const getMenuButtons = (props) => {
        return scrollSections.map(({id, onClick, selected}) => {
            const onClickWrapper = () => {
                onClick();
                handleDrawerClose();
            }
            return <MenuItem className={classes.menuItem} key={id} onClick={onClickWrapper} selected={selected}>
                {id.toUpperCase()}
            </MenuItem>});
        
    }

    const displayDesktop = () => {
        return <Toolbar>
            {sandoneappsLogo}
            <div className={classes.grow} />
            {getMenuButtons({component: "Button"})}
        </Toolbar>;
    };

    const displayMobile = () => {
        return (
            <Toolbar>
                <IconButton 
                    {...{
                        edge: "start",
                        color: "inherit",
                        ref: menuButtonRef,
                        // "aria-controls": "nav-menu",
                        // "aria-haspopup": "true",
                        onClick: handleDrawerOpen,
                        className: classes.menuicon,
                    }}
                    >
                    <MenuIcon />
                </IconButton>
                <div className={classes.grow}/>
                {sandoneappsLogo}
                <Menu
                    {...{
                    id: "nav-menu",
                    open: Boolean(state.anchorEl),
                    anchorEl: state.anchorEl,
                    getContentAnchorEl: null,
                    onClose: handleDrawerClose,
                    }}
                >
                    {getMenuButtons({component:"MenuItem"})}
                </Menu>
            </Toolbar>
        );
    }
  
    return (
        <header className={classes.root}>
            <AppBar position="fixed" className={classes.headerStyleClass}>
                {/* {console.log("Render mView: ", state.mobileView)} */}
                {state.mobileView ? displayMobile() : displayDesktop()}
            </AppBar>
        </header>
    );
}