import React from "react";
import { Typography, Paper, Grid, Fab } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ScrollingProvider, Section } from "react-scroll-section";

import SectionContainer from "../containers/SectionContainer"
import ScrollTop from "../components/ScrollTopButton";

export default function Body (props) {
    return (
        
        <Grid>
            <div id="back-to-top-anchor" />
            <Section id="home">
                <SectionContainer>
                    <Typography variant="h4" component="h1"> Apps for a better </Typography>
                    <Typography variant="h3" component="h1"> every day </Typography>
                </SectionContainer>
            </Section>
            <Section id="kasheer">
                <SectionContainer>
                    <Typography variant="h4" component="h1"> Kasheer </Typography>
                </SectionContainer>
            </Section>
            <Section id="about">
                <SectionContainer>
                    <Typography variant="h4" component="h1"> About </Typography>
                </SectionContainer>
            </Section>
            <Section id="contact">
                <SectionContainer>
                    <Typography variant="h4" component="h1"> Contact </Typography>
                </SectionContainer>
            </Section>
            <ScrollTop {...props}>
                <Fab size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
        </Grid>
        // </ScrollingProvider>
    );
}